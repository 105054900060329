import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../logo/logo";
import scLogo from "../../assets/scLogo.png";
import CallingCard, { CallingOption } from "../calling-card/calling-card";
import brandLogo from "../../assets/es-logo.png";
import CopyRight from "../atoms/copy-right/copy-right";
import { IClient } from "../../network/models";

const Call: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate(); // 👈 Use navigate for redirection
  const tenant: IClient | undefined = location.state?.tenant; // 👈 Ensure it's undefined-safe
  const [callOption, setCallOption] = useState<CallingOption>(
    CallingOption.InAppCall
  );

  useEffect(() => {
    if (!tenant) {
      console.warn("No client found, redirecting...");
      navigate("/choose-client"); // 👈 Redirect if no client
      return;
    }
  }, [tenant, navigate]);

  if (!tenant) {
    return null; // 👈 Prevent rendering until redirect happens
  }

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="min-w-[450px] p-8 drop-shadow-lg text-center bg-white">
        <Logo logo={brandLogo} />

        <CallingCard
          id="general"
          isDisabled={false}
          logo=""
          tenantId={tenant.tenantId} // ✅ Safe now!
          businessName={tenant.name}
          allowedIntents={Object.values(tenant.presetIntents)}
          callStarted={() => {}}
          callEnded={() => {}}
          callingOption={callOption}
        />

        <div className="mt-2">
          <Logo logo={scLogo} h="h-12" w="w-12" />
          <CopyRight textColor="text-gray-800" />
        </div>
      </div>
    </div>
  );
};

export default Call;
