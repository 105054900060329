import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import { awsConfig } from "./awsConfig";

const userPool = new CognitoUserPool({
  UserPoolId: awsConfig.UserPoolId,
  ClientId: awsConfig.ClientId,
});

export const signUp = (
  username: string,
  password: string,
  givenName: string,
  familyName: string,
  phoneNumber: string,
  callback: (err: any, result: any) => void
) => {
  const attributeList = [
    new CognitoUserAttribute({ Name: "given_name", Value: givenName }),
    new CognitoUserAttribute({ Name: "family_name", Value: familyName }),
    new CognitoUserAttribute({ Name: "phone_number", Value: phoneNumber }),
  ];

  userPool.signUp(username, password, attributeList, [], callback);
};

export const setNewPassword = (
  cognitoUser: CognitoUser,
  newPassword: string
): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    if (!cognitoUser) {
      reject(new Error("Invalid Cognito user"));
      return;
    }
    // Complete the new password challenge
    cognitoUser.completeNewPasswordChallenge(
      newPassword,
      {}, // Pass missing attributes
      {
        onSuccess: (result) => {
          console.log("Password updated successfully:", result);
          resolve(true);
        },
        onFailure: (err) => {
          console.error("Error setting new password:", err);
          reject(err);
        },
      }
    );

  });
};

export const signIn = (
  username: string,
  password: string,
  callback: (err: any, result: any, passwordResetRequired: boolean, userAttributes?: any, requiredAttributes?: any, cognitoUser?: any) => void
) => {
  const authenticationDetails = new AuthenticationDetails({
    Username: username,
    Password: password,
  });

  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: (result) => callback(null, result, false),
    onFailure: (err) => callback(err, null, false),
    newPasswordRequired: (userAttributes, requiredAttributes) => {
      callback(null, null, true, userAttributes, requiredAttributes, cognitoUser);
    },
  });
};

export const forgotPassword = (
  username: string,
  callback: (err: any, result: any) => void
) => {
  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool,
  });
  cognitoUser.forgotPassword({
    onSuccess: (result) => callback(null, result),
    onFailure: (err) => callback(err, null),
  });
};

export const confirmationCode = (
  username: string,
  verificationCode: string,
  newPassword: string,
  callback: (err: any, result: any) => void
) => {
  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool,
  });
  cognitoUser.confirmPassword(verificationCode, newPassword, {
    onSuccess: (result) => callback(null, result),
    onFailure: (err) => callback(err, null),
  });
};

export const changePassword = (
  username: string,
  oldPassword: string,
  newPassword: string,
  callback: (err: any, result: any) => void
) => {
  const cognitoUser = userPool.getCurrentUser();
  cognitoUser?.getSession((err: Error | null, session: any) => {
    if (err) {
      callback(err, null);
    }
    if (session) {
      console.log("Session Valid");
    }
  });

  cognitoUser?.changePassword(oldPassword, newPassword, (err, result) => {
    if (err) {
      callback(err, null);
    } else {
      callback(null, result);
    }
  });
};

export const updateUserInfo = (
  username: string,
  givenName: string,
  familyName: string,
  phoneNumber: string,
  callback: (err: any, result: any) => void
) => {
  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  const attributeList = [
    new CognitoUserAttribute({ Name: "given_name", Value: givenName }),
    new CognitoUserAttribute({ Name: "family_name", Value: familyName }),
    new CognitoUserAttribute({ Name: "phone_number", Value: phoneNumber }),
  ];

  cognitoUser.updateAttributes(attributeList, (err, result) => {
    if (err) {
      callback(err, null);
    } else {
      callback(null, result);
    }
  });
};

export const signOut = () => {
  const username = userPool.getCurrentUser()?.getUsername();

  if (!username) {
    const cognitoUser = new CognitoUser({
      Username: username!,
      Pool: userPool,
    });

    cognitoUser.signOut();
    console.log(`User ${username} has been signedout...`);
  } else {
    console.log(`User not found`);
  }
};

export const confirmSignUp = (
  username: string,
  code: string,
  callback: (err: any, result: any) => void
) => {
  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  cognitoUser.confirmRegistration(code, true, callback);
};
