import { IClient, IOutgoingNumber } from "../network/models";

interface IApiResponse<T> {
    scResponse: {
        data: T;
    };
}

export class ClientService {
    private appDomain = `${process.env.REACT_APP_CC_DOMAIN}`;
    private accessToken: string;

    constructor(accessToken: string) {
        this.accessToken = accessToken;
    }

    async getClientsAsync(): Promise<IClient[]> {
        const URL = `https://${this.appDomain}/cc/tenants`;

        try {
            const response = await fetch(URL, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data: IApiResponse<IClient[]> = await response.json();
            return data.scResponse.data;
        } catch (error) {
            console.error("Failed to fetch clients:", error);
            return [];
        }
    }

    async getClientNumberAsync(tenantId: string): Promise<IOutgoingNumber[]> {
        const URL = `https://${this.appDomain}/cc/tenant/${tenantId}/numbers`;

        try {
            const response = await fetch(URL, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data: IApiResponse<IOutgoingNumber[]> = await response.json();
            return data.scResponse.data;
        } catch (error) {
            console.error("Failed to fetch clients:", error);
            return [];
        }
    }
}