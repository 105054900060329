import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ILoggedInUser, useAuth } from "../../auth/AuthContext";
import { TokenHelper } from "../../auth/tokenHelper";
import { setNewPassword, signIn } from "../../auth";
import scLogo from "../../assets/scLogo.png";
import Logo from "../logo/logo";
import { ClientService } from "../../services/clientService";
import ProgressModal from "../molecules/progress-modal/progress-modal";

const SetNewPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const username = location.state?.businessEmail;
  const { cognitoUser, login } = useAuth();
  const [password, setPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("");
  const tokenHelper = useRef<TokenHelper | null>(null);
  const clientService = useRef<ClientService | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!username || !cognitoUser) navigate("/login");
  }, []);

  const handleSubmit = async () => {
    if (password != confirmNewPassword) {
      setError("New password and Confirm new password do not match");
    }

    try {
      if (cognitoUser) {
        const success = await setNewPassword(cognitoUser, password);

        if (success) {
          const businessEmail = cognitoUser.getUsername();

          signIn(businessEmail, password, async (err, result) => {
            if (err) {
              if (err.code === "UserNotConfirmedException") {
                navigate("/verify", {
                  state: { businessEmail, password },
                });
              } else {
                setError(err.message || JSON.stringify(err));
              }
            } else {
              // Decode token
              const tokenProps = await tokenHelper.current?.decodeToken(
                result.accessToken.jwtToken
              );

              const loggedInUser: ILoggedInUser = {
                id: result.idToken.payload.sub,
                givenName: result.idToken.payload.given_name,
                familyName: result.idToken.payload.family_name,
                username: businessEmail,
                phoneNumber: result.idToken.payload.phone_number,
                ex_user_id: result.accessToken.payload.ex_user_id,
                accessToken: result.accessToken.jwtToken,
                num: result.accessToken.payload.num,
                accountId: tokenProps?.account_id ?? "",
                email: tokenProps?.email ?? "",
                role: tokenProps?.role,
                exp: result.accessToken.payload.exp,
              };

              login(loggedInUser);

              clientService.current = new ClientService(
                result.accessToken.jwtToken
              );
              const tenants = await clientService.current.getClientsAsync();

              if (tenants.length === 0) {
                navigate("/error");
              }

              setLoading(false);
              if (tenants && tenants.length > 1) {
                navigate("/choose-client", { state: { tenants } });
              } else {
                const tenant = tenants[0];
                navigate("/call", { state: { tenant } });
              }
              setLoading(false);
            }
          });
        }
      } else {
        setError("User session not available.");
      }
    } catch (error) {
      console.error("Failed to set new password:", error);
      setError("Failed to update password. Please try again.");
    }
  };

  return (
    <>
      <div className="bg-gradient-to-r from-gray-700 to-gray-900 flex flex-col items-center justify-start md:justify-center h-screen w-screen py-8">
        <div className="min-w-[600px] bg-white text-gray-900 flex flex-col gap-6 items-center justify-start md:justify-center h-fit w-fit p-12 md:shadow-[0_30px_50px_rgba(0,0,0,.5)]">
          <Logo logo={scLogo} businessName="Secured calls"></Logo>
          <span className="font-Poppins font-semibold text-md">
            Set new password for
          </span>
          <span className="font-Poppins font-semibold text-md">{username}</span>
          <input
            type="password"
            className="p-2 border rounded w-full"
            placeholder="New password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            type="password"
            className="p-2 border rounded w-full"
            placeholder="Confirm new password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
          <button
            type="submit"
            className="font-Poppins p-2 bg-blue-500 text-white rounded hover:bg-blue-800 drop-shadow-lg"
            onClick={handleSubmit}
            disabled={!password || !confirmNewPassword}
          >
            Change
          </button>
        </div>
        <ProgressModal
          label="Logging you in!"
          isVisible={loading}
        ></ProgressModal>
      </div>
    </>
  );
};

export default SetNewPassword;
