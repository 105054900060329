import { IClient } from "../../network/models";
import { useEffect, useState } from "react";
import AlertLabel from "../atoms/alert-label/alert-label";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../logo/logo";
import scLogo from "../../assets/scLogo.png";
const ChooseClient = () => {
  const location = useLocation();
  const tenants: IClient[] = location.state?.tenants || [];
  const [showNoClientsError, setShowNoClientsError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!tenants || tenants.length === 0) {
      setShowNoClientsError(true);
    } else {
      setShowNoClientsError(false);
    }
  }, []);

  const handleClientSelect = (tenant: IClient) => {
    navigate(`/call`, { state: { tenant: tenant } });
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center gap-6 w-full px-4">
      {/* Title */}
      <Logo logo={scLogo}></Logo>
      <h1 className="text-white text-2xl font-semibold tracking-wider">
        Choose a client
      </h1>
      {/* Client Selection Grid */}
      <div className="max-w-4xl flex items-center justify-center gap-4 overflow-x-auto px-24 py-6 scroll-auto">
        {tenants.map((client) => (
          <>
            <button
              key={client.tenantId}
              onClick={() => handleClientSelect(client)}
              className="p-4 bg-gradient-to-r from-sky-600 to-sky-800 text-white text-nowrap font-medium rounded-md shadow-lg hover:bg-blue-700 transition flex items-center justify-center"
            >
              {client.name}
            </button>
          </>
        ))}
      </div>

      {/* Error Message (if no clients available) */}
      {showNoClientsError && (
        <div className="mt-4">
          <AlertLabel
            type="error"
            message="No clients available."
            onHide={() => setShowNoClientsError(false)}
          />
        </div>
      )}
    </div>
  );
};

export default ChooseClient;
