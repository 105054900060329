import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signIn } from "../../auth";
import Logo from "../logo/logo";
import scLogo from "../../assets/scLogo.png";
import CopyRight from "../atoms/copy-right/copy-right";
import ProgressModal from "../molecules/progress-modal/progress-modal";
import AlertLabel from "../atoms/alert-label/alert-label";
import { ILoggedInUser, useAuth } from "../../auth/AuthContext";
import { TokenHelper } from "../../auth/tokenHelper";
import { ClientService } from "../../services/clientService";

const Login: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const tokenHelper = useRef<TokenHelper | null>(null);
  const clientService = useRef<ClientService | null>(null);
  const { login, setCognitoUserContext: setCognitoUser } = useAuth();

  const handleForgotPassword = () => {
    console.log(`Forgot password clicked..`);
    navigate("/request-code");
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    setError("");

    if (!username || !password) {
      setError("Missing credentials");
      return;
    }

    setLoading(true);

    signIn(
      username,
      password,
      async (
        err,
        result,
        passwordResetRequired,
        userAttributes,
        requiredAttributes,
        cognitoUser
      ) => {
        if (passwordResetRequired) {
          console.log(`userAttributes`, userAttributes);
          console.log(`requiredAttributes`, requiredAttributes);
          console.log(`requiredAttributes`, cognitoUser);
          setCognitoUser(cognitoUser);
          navigate("/set-new-password", {
            state: { businessEmail: username },
          });
          return;
        }

        if (err) {
          setLoading(false);
          if (err.code === "UserNotConfirmedException") {
            navigate("/verify", {
              state: { username, password },
            });
          } else {
            setError(err.message || JSON.stringify(err));
          }
        } else {
          // Decode token
          const tokenProps = await tokenHelper.current?.decodeToken(
            result.accessToken.jwtToken
          );

          console.log(`token props`, tokenProps);

          const loggedInUser: ILoggedInUser = {
            id: result.idToken.payload.sub,
            givenName: result.idToken.payload.given_name,
            familyName: result.idToken.payload.family_name,
            username: username,
            phoneNumber: result.idToken.payload.phone_number,
            ex_user_id: result.accessToken.payload.ex_user_id,
            accessToken: result.accessToken.jwtToken,
            num: result.accessToken.payload.num,
            accountId: tokenProps?.account_id ?? "",
            email: tokenProps?.email ?? "",
            role: tokenProps?.role,
            exp: result.accessToken.payload.exp,
          };

          console.log(`logged in user`, loggedInUser);

          login(loggedInUser);

          clientService.current = new ClientService(
            result.accessToken.jwtToken
          );
          const tenants = await clientService.current.getClientsAsync();

          if (tenants.length === 0) {
            navigate("/error");
          }

          setLoading(false);
          if (tenants && tenants.length > 1) {
            navigate("/choose-client", { state: { tenants } });
          } else {
            const tenant = tenants[0];
            navigate("/call", { state: { tenant } });
          }
          setLoading(false);
        }
      }
    );

    // signIn(username, password, async (err, result) => {
    //   if (err) {
    //     setLoading(false);
    //     console.log(`Login error ${JSON.stringify(err)}`);
    //     if (err.code === "UserNotConfirmedException") {
    //       navigate("/verify-code");
    //     }
    //     setError(err.message || JSON.stringify(err));
    //   } else {
    //     const tokenProps = await tokenHelper.current?.decodeToken(
    //       result.accessToken.jwtToken
    //     );

    //     console.log(`result -`, result);

    //     login({
    //       id: result.idToken.payload.sub,
    //       givenName: result.idToken.payload.given_name,
    //       familyName: result.idToken.payload.family_name,
    //       username: username,
    //       phoneNumber: result.idToken.payload.phone_number,
    //       ex_user_id: result.accessToken.payload.ex_user_id,
    //       accessToken: result.accessToken.jwtToken,
    //       num: result.accessToken.payload.num,
    //       accountId: tokenProps?.account_id ?? "",
    //       email: tokenProps?.email ?? "",
    //       role: tokenProps?.role,
    //     });

    //     clientService.current = new ClientService(result.accessToken.jwtToken);
    //     const tenants = await clientService.current.getClientsAsync();

    //     if (tenants.length === 0) {
    //       navigate("/error");
    //     }

    //     setLoading(false);
    //     if (tenants && tenants.length > 1) {
    //       navigate("/choose-client", { state: { tenants } });
    //     } else {
    //       const tenant = tenants[0];
    //       navigate("/call", { state: { tenant } });
    //     }
    //   }
    // });
  };

  const handleCreateNewAccount = () => {
    navigate("/sign-up");
  };

  return (
    <div className="font-Poppins flex items-center justify-center h-screen bg-slate-800">
      <div className="bg-slate-200 min-w-[450px] p-8 shadow-lg text-center bg-gradient-to-tr from-slate-950 to-sky-900">
        <Logo logo={scLogo} businessName="Secured calls"></Logo>
        <h1 className="font-Poppins font-semibold text-xl text-white">
          SIGN IN
        </h1>
        <h2 className="font-Poppins font-semibold text-lg text-white uppercase">
          Secure CX
        </h2>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 mt-4">
          <input
            type="text"
            className="p-2 border rounded"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            className="p-2 border rounded"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="button"
            className="font-Poppins text-right text-indigo-400 underline bg-transparent border-none cursor-pointer p-0"
            onClick={handleForgotPassword}
          >
            forgot password
          </button>
          <button
            type="submit"
            className="font-Poppins p-2 bg-blue-500 text-white rounded hover:bg-blue-800 uppercase drop-shadow-lg"
            onClick={handleSubmit}
          >
            Sign In
          </button>

          <button
            type="button"
            className="font-Poppins p-2 bg-orange-500 text-white rounded hover:bg-orange-800 uppercase drop-shadow-lg"
            onClick={handleCreateNewAccount}
          >
            Create a new Account
          </button>
        </form>
        <br></br>
        {error && (
          <AlertLabel
            type={"error"}
            message={error}
            onHide={() => {
              setError("");
            }}
          ></AlertLabel>
        )}

        <br></br>
        <CopyRight></CopyRight>
        <ProgressModal
          label="Logging you in!"
          isVisible={loading}
        ></ProgressModal>
      </div>
    </div>
  );
};

export default Login;
