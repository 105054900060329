interface CopyRightProps {
  textColor?: string; // Optional prop for text color
}

const CopyRight: React.FC<CopyRightProps> = ({ textColor = "text-white" }) => {
  return (
    <span
      className={`font-Poppins font-semibold tracking-wider ${textColor} text-md`}
    >
      © {new Date().getFullYear()} Expertstack Pty Ltd | All Rights Reserved.
    </span>
  );
};

export default CopyRight;
