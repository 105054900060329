// src/components/VerifyCode.tsx

import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmSignUp, signIn } from "../../auth";
import Logo from "../logo/logo";
import InputWithIcon from "../atoms/input-with-icon/input-with-icon";
import { Binary, Mail, ShieldCheck } from "lucide-react";
import scLogo from "../../assets/scLogo.png";
import CopyRight from "../atoms/copy-right/copy-right";
import { useAuth } from "../../auth/AuthContext";
import { TokenHelper } from "../../auth/tokenHelper";
import { ClientService } from "../../services/clientService";

const VerifyCode: React.FC = () => {
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Added loading state
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth(); // Get the login function from the AuthContext
  const tokenHelper = useRef<TokenHelper | null>(null);
  const clientService = useRef<ClientService | null>(null);

  const username = location.state?.username;
  const password = location.state?.password;

  useEffect(() => {
    if (!username || !password) {
      navigate("/login");
    }
  }, [username, password, navigate]);

  const handleVerify = () => {
    setError("");
    if (!username || !password) {
      navigate("/login");
      return;
    }
    setLoading(true); // Set loading to true when verification starts
    confirmSignUp(username, verificationCode, async (err, result) => {
      if (err) {
        setError(err.message || JSON.stringify(err));
        setLoading(false); // Set loading to false if there is an error
      } else {
        try {
          // console.log(`before sign-in`);
          signIn(username, password, async (err, result) => {
            if (err) {
              console.log(`error sign-in`);
              setError(err.message || JSON.stringify(err));
              setLoading(false); // Set loading to false if there is an error
            } else {
              // console.log(`sign-in success`);
              // Call the API after successful sign-in
              // console.log(`Login result ${JSON.stringify(result)}`);
              const tokenProps = await tokenHelper.current?.decodeToken(
                result.accessToken.jwtToken
              );

              login({
                id: result.idToken.payload.sub,
                givenName: result.idToken.payload.given_name,
                familyName: result.idToken.payload.family_name,
                username: username,
                phoneNumber: result.idToken.payload.phone_number,
                ex_user_id: result.accessToken.payload.ex_user_id,
                accessToken: result.accessToken.jwtToken,
                num: result.accessToken.payload.num,
                accountId: tokenProps?.account_id ?? "",
                email: tokenProps?.email ?? "",
                role: tokenProps?.role,
              }); // Set the authentication state to true with user data

              clientService.current = new ClientService(
                result.accessToken.jwtToken
              );

              const clients = await clientService.current.getClientsAsync();

              if (clients.length === 0) {
                navigate("/error");
              }

              if (clients && clients.length === 1) {
                navigate("/choose-client", { state: { clients } });
              } else {
                const theClient = clients[0];
                navigate("/call", { state: { theClient } });
              }
            }
          });
        } catch (apiError) {
          setError("Error calling the API");
          setLoading(false); // Set loading to false if there is an API error
        }
      }
    });
  };

  return (
    <div className="flex items-center justify-center h-screen bg-slate-800 ">
      <div className="bg-slate-200 p-8 rounded-lg shadow-lg text-center bg-gradient-to-tr from-slate-950 to-sky-900 max-w-[450px]">
        <div className="flex flex-col items-center justify-center gap-3 bg-slate-800)">
          <Logo logo={scLogo}></Logo>
          <span className="text-white">
            Please enter the verification code your received on your email{" "}
            {username}
          </span>
          <InputWithIcon
            icon={<Binary className="h-5 w-5 text-gray-600" />}
            label={"code"}
            placeholder="email verification code"
            value={verificationCode}
            maxlength={6}
            onChange={function (value: string): void {
              setVerificationCode(value);
            }}
            onClear={() => {}}
          ></InputWithIcon>
          {/* <input
          type="text"
          className="p-2 border rounded w-full"
          placeholder="Email address"
          value={emailId}
          onChange={(e) => setEmailId(e.target.value)}
        /> */}
          <div className="flex gap-2">
            <button
              onClick={handleVerify}
              className="font-Poppins p-2 min-w-32 bg-blue-500 text-white rounded hover:bg-blue-800 uppercase"
            >
              <span className="flex items-center justify-center gap-1 text-center">
                Verify <ShieldCheck />
              </span>
            </button>
          </div>
          {error && (
            <div className="text-red-500">
              {error}

              <a
                href="mailto:hello@securedcalls.com?subject=Registration%20for%20trials"
                className="font-normal text-white"
              >
                <span className="flex items-center justify-center gap-1 text-center">
                  <Mail />
                  Support
                </span>
              </a>
            </div>
          )}
          <br></br>
          <CopyRight></CopyRight>
        </div>
      </div>
    </div>
  );
};

export default VerifyCode;
